@import "@geolib/geolib-client/styles/variables.scss";
@import "@geolib/geolib-client/styles.scss";


footer.navbar-fixed-bottom {
  display: $geoinfoGapExtraLarge;
}

.gp-page-header .gp-container-normal {
  margin-top: 30px;
  margin-bottom: $geoinfoGapExtraLarge;
}

.margin-top-extra-large {
  margin-top: $geoinfoGapExtraLarge;
}

.flex-start-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.btn-group {
  justify-content: flex-end;
  align-items: center;

  gp-spinner {
    margin-left: 5px;
  }
}

.btn-back {
  margin-right: 8px;
}

button {
  margin-left: 8px;

  &:first-of-type {
    margin-left: 0;
  }
}

.float-right {
  justify-content: flex-end;
}

// Used where label-tag interferes with functionality of dx-select-box
// ToDo: add to all dx-select-box
.select-box-label {
  margin-bottom: 10px;
}

.dx-popup-content {
  overflow-y: auto;
}

.dx-popup-content distribution-edit {

  .gp-page-header {
    display: none;
  }

  .gp-container {
    margin: 0px;
  }

  .card {
    border: none;
    margin-bottom: $geoinfoGapMedium;
  }
}

input[required] {
  background-image: url("/images/required.png");
  background-position: right 10px center;
  background-repeat: no-repeat;
  padding-right: 24px;
}

.dx-datebox[required],
.dx-selectbox[required] {
  background-image: url("/images/required.png");
  background-position: right 40px center;
  background-repeat: no-repeat;
}

select {
  &:required {
    background: url("/images/required.png") no-repeat right center;
    background-origin: content-box;
    padding-right: 25px;
  }
}

.loading-spinner {
  margin-right: 5px;
}

dx-data-grid.hide-overflow {
  overflow: hidden;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines.inactive > td,
.dx-datagrid-rowsview .dx-row:not(.dx-row-focused).dx-column-lines.inactive > td {
  color: $geoinfoGrey3;
}

// TODO: may be deleted when the version of the geoappbase-client is bigger than 40.2.7. check if the moreNavigation in the header looks ok.

.dx-dropdownbutton-popup-wrapper:not(.gp-drop-down-button-with-dx-list) {
  .dx-overlay-content {
    .dx-item.dx-list-item[title="undefined"] {
      p {
        margin-bottom: 0;
      }

      &.dx-state-focused,
      &.dx-state-active,
      &.dx-state-hover {
        background: unset;
      }

      dxi-item {
        padding: 0;

        .gp-delemiter {
          border-top-color: $geoinfoGreyLight3;
          height: 1px;
          margin: 9px 0;
          overflow: hidden;
        }
      }
    }
  }
}

// TODO: may be deleted when the version of the geoappbase-client is bigger than 40.2.7. check if the moreNavigation in the header looks ok.
